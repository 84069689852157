import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import Layout from '../../components/Layout';
import TitleAndTheme from '../../components/TitleAndTheme';
import ProjectHeader from '../../components/ProjectHeader';
import Grid from '../../components/Grid';
import AssetWithCaption from '../../components/AssetWithCaption';
import Text from '../../components/Text';
import { comfortableColWidth } from '../../utils/gridWidths';

const LyricCardGenerator = () => {
  const [linkAnimation, setTitleAnimation] = useSpring(() => ({
    from: { xy: [0, 0] },
    to: { xy: [0, 0] },
  }));

  const animateRight = () => setTitleAnimation({ xy: [12, 0] });

  const resetAnimation = () => setTitleAnimation({ xy: [0, 0] });

  return (
    <Layout>
      <TitleAndTheme
        title="Edwin Morris, Genius lyric card generator"
        themeClass="theme--lyriccardgenerator"
      />

      <ProjectHeader
        name="Lyric Card Generator"
        subhead="A web-based design tool for Genius’ social media team"
        sectionid="lyriccardgenerator"
      />

      <Grid verticalMargin="top">
        <Notice
          to="/lyriccardgenerator/tool/"
          onMouseEnter={animateRight}
          onMouseLeave={resetAnimation}
        >
          <p>Looking for the tool and not my portfolio entry?</p>
          <CTA>
            <animated.div
              style={{
                transform: linkAnimation.xy.interpolate(
                  (x, y) => `translate3d(${x}px, ${y}px, 0px)`
                ),
              }}
            >
              Use the lyric card generator{' '}
              <CTAArrow viewBox="0 0 70 50" xmlns="http://www.w3.org/2000/svg">
                <g transform="rotate(-180 35 25)">
                  <path
                    d="M5.787 26.5l21.355 21.355L25 49.998l-25-25L25 0l2.142 2.143L5.784 23.5H70v3H5.787"
                    fillRule="evenodd"
                  />
                </g>
              </CTAArrow>
            </animated.div>
          </CTA>
        </Notice>
      </Grid>

      <Text>
        <p>
          The social media team at Genius posts on Instagram up to 20 times per
          day. Many posts are text-heavy, and use a template: artist quotes,
          Genius articles, tweets, and lyric cards. These templates are
          easy-to-edit Photoshop files, which the social media team will open,
          edit, and export. This process works for everything except lyrics,
          which we display with a text highlight — the highlight effect is easy
          to render in a browser, but tedious in Photoshop.
        </p>
        <p>
          I built a web-based design tool that the social media team uses every
          day to create lyric cards quickly.
        </p>
      </Text>

      <Grid>
        <AssetWithCaption
          position="full"
          image="lyriccardgenerator/instagram"
          width="2880"
          height="1800"
        >
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://instagram.com/genius"
          >
            Genius’ Instagram page
          </a>
          . From the top-left: A tweet, a lyric card, trivia, a Genius article,
          a tweet, a lyric card, a music video screenshot, a lyric card, and a
          picture.
        </AssetWithCaption>
      </Grid>

      <Text>
        <p>
          The Lyric Card Generator is a React app. Cards are rendered with HTML
          and CSS, and turned into downloadable images in the background with{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://github.com/niklasvh/html2canvas"
          >
            html2canvas
          </a>
          .
        </p>

        <p>
          Everything the user sees is interactive, but in more limited ways than
          in Photoshop. Images can be positioned by dragging or scaling, but not
          moved off-screen, or zoomed to the point of pixelation; text can be
          edited, but only resized with a toggle at the bottom of the screen.
          Small warnings will nudge the user to correct easy-to-miss mistakes.
          It’s like Photoshop with lane bumpers.
        </p>
      </Text>

      <Grid>
        <AssetWithCaption
          position="full"
          video="lyriccardgenerator/extensionwarningsmovement_fast_small"
          width="1600"
          height="1000"
        >
          Dragging an Instagram image into the Lyric Card Generator. This also
          demonstrates{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://chrome.google.com/webstore/detail/high-resolution-downloade/hbijmiokbffalbolieapplfhmmnioeao"
          >
            a Chrome extension I made
          </a>{' '}
          for this project.
        </AssetWithCaption>
        <AssetWithCaption
          position="left_half"
          video="lyriccardgenerator/dragandzoom_small"
          width="1600"
          height="1000"
        >
          Warnings like this catch mistakes that are often too small for users
          to notice on their own.
        </AssetWithCaption>
        <AssetWithCaption
          position="right_half"
          image="lyriccardgenerator/warnings"
          width="2880"
          height="1800"
        >
          Download is disabled for an image that doesn’t fill the card, but
          enabled for an image that’s too pixelated. Pixelation is sometimes a
          necessity when image sources are low quality.
        </AssetWithCaption>
        <AssetWithCaption
          position="full"
          video="lyriccardgenerator/textpasteandedit_fast_small"
          width="1600"
          height="1000"
        >
          Pasted lyrics are automatically edited to include curly quotes and
          apostrophes.
        </AssetWithCaption>
      </Grid>

      <Text>
        <p>
          Two different color libraries are used to set the attribution bar’s
          color:{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.npmjs.com/package/color-thief-browser"
          >
            Color Thief
          </a>{' '}
          and{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.npmjs.com/package/tinycolor2"
          >
            Tiny Color
          </a>
          . When an image is dropped onto the page, its dominant color is
          extracted by Color Thief and set as the bar’s background color. Then,
          Tiny Color determines whether white or black text is more readable on
          that color.
        </p>
        <p>
          The user can change the pre-selected color with Chrome’s built-in
          color picker, or even use an eye-dropper tool to pick a different
          color from the image.
        </p>
      </Text>

      <Grid>
        <AssetWithCaption
          position="left_half"
          image="lyriccardgenerator/colorpicker"
          width="2880"
          height="1800"
        >
          Picking a new color with the eye-dropper.
        </AssetWithCaption>

        <AssetWithCaption
          position="right_half"
          video="lyriccardgenerator/autotextcolor_fast_small"
          width="1600"
          height="1000"
        >
          Tiny Color adjusting the text color as the background color changes.
        </AssetWithCaption>
      </Grid>

      <Text noBottomMargin>
        <p>
          The code for this project isn’t publicly available, but{' '}
          <Link to="/lyriccardgenerator/tool/">
            you can use the tool live here
          </Link>
          .
        </p>
      </Text>
    </Layout>
  );
};

export default LyricCardGenerator;

const Notice = styled(Link)`
  grid-column: left-text-start / right-text-end;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 1em 1.5em;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  text-decoration: inherit;
  background: var(--theme-placeholder);

  @media screen and (max-width: ${`${32 * comfortableColWidth}px`}) {
    display: block;
    margin-left: -1.5em;
    margin-right: -1.5em;
  }
`;

const CTA = styled.div`
  font-size: inherit;
  -webkit-appearance: none;
  cursor: pointer;
  background: transparent;
  border-radius: 16px;
  border: none;
  font-weight: bold;
`;

const CTAArrow = styled.svg`
  height: 1em;
  stroke: var(--theme-primary);
  stroke-width: 2px;
  position: relative;
  top: 2px;
  margin-left: 0.25em;
`;
